import React from "react";

import LayoutBlog from "../components/LayoutBlog";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LogoFull from "../components/LogoFull";
import EmailForm from "../components/EmailForm";
import EmailFormInPage from "../components/EmailFormInPage";
import BackToHomeArrow from "../components/BackToHomeArrow";
import ShareLinks from "../components/ShareLinks";
import PopUpOnExitEmailForm from '../components/PopUpOnExitEmailForm';

const BlogMain = () => (
  <LayoutBlog
    title={"Crafted #008 - Toast Tiensvold, Toasty Bros. Brewing"}
    blogUrl={"https://joincrafted.com/008-toast-tiensvold-toasty-bros"}
    description={
      "Toast Tiensvold of Toasty Bros. Brewing (Denton, TX) share what it means to run a Mom-and-Pop-scale brewing operation"
    }
    imageUrl={
      "https://www.joincrafted.com/assets/images/toast/toast_long_tacky_old_slacks_original.jpg"
    }>
    <Header />
    <div>
      <main className="blog-main" role="main">
        <div className="blog-left">
          <ShareLinks
            twitterLink={
              "https://twitter.com/intent/tweet?text=Toast%20Tiensvold%20of%20Toasty%20Bros.%20Brewing%20(Denton,%20TX)%20share%20what%20it%20means%20to%20run%20a%20Mom-and-Pop-scale%20brewing%20operation%0A%0Ahttps%3A//joincrafted.com/008-toast-tiensvold-toasty-bros"
            }
            facebookLink={
              "https://www.facebook.com/sharer/sharer.php?u=https%3A//joincrafted.com/008-toast-tiensvold-toasty-bros"
            }
          />
        </div>
        <div className="slim-container">
          <div className="mt-4 mx-2 d-flex justify-content-between">
            <div>
              <BackToHomeArrow />
            </div>
            <div
              style={{
                color: "rgba(40, 50, 78, 0.5)",
                fontSize: "16px",
                lineHeight: "1.5"
              }}
            >
              <p>November 30, 2020</p>
            </div>
          </div>
          <div className="100vw d-flex justify-content-center mt-4">
            <LogoFull></LogoFull>
          </div>
          <table className="document" align="center">
        <tbody>
          <tr>
            <td valign="top">
              <table cellSpacing={0} cellPadding={0} border={0} align="center" width={630} className="container">
                <tbody>
                  <tr className="inner-body">
                    <td>
                      <table cellSpacing={0} cellPadding={0} border={0} align="center" width="100%">
                        <tbody>
                          <tr bgcolor="#ffffff" style={{backgroundColor: '#ffffff'}}>
                            <td className="container">
                              <table cellSpacing={0} cellPadding={0} border={0} align="center" width="100%">
                                <tbody>
                                  <tr>
                                    <td style={{padding: '5px 5px'}}>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{textAlign: 'center', marginBottom: '2em', color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <h1 style={{color: "rgba(40, 50, 78, 0.5)", fontWeight: '400', fontSize: '16px', lineHeight: '1.5'}}><span style={{display: 'none'}}>Crafted </span>Edition &#35;008 🍻 - Toast Tiensvold, Toasty Bros. Brewing</h1>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5', paddingBottom: '16px'}}>
                                                <p>There are often-told stories of passionate homebrewers who turn their hobby into a career, only to eventually lose the love they once had for the craft.</p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5', paddingBottom: '16px'}}>
                                                <p>The joy they find in brewing is sacrificed for acheiving "success".</p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5', paddingBottom: '16px'}}>
                                                <p>It's not just in brewing. We see it happen across every creative profession - from sports to music to fine art. But it doesn't have to be this way. You <em>can</em> wake up daily to do what you love, and keep loving it.</p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '16px', lineHeight: '1.5', margin: '8px 0 32px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>Toast Tiensvold</strong> of <a href="https://toastybros.com/" style={{color: '#6821AB', fontWeight: 700, textDecoration: 'underline'}} target="_blank" rel="noreferrer"><strong>Toasty Bros. Brewing</strong></a> is evidence of that. I'm excited to share about his work because its always refreshing to learn about someone who get's to do daily what they love. It inspires us to strive for the same.
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5', paddingBottom: '16px'}}>
                                                <p>Brewing professionally isn't easy - but then again, almost nothing worthwhile is 100% easy. I hope as you read, you'll pick up on the attitude behind the words of Toast, and be encouraged by someone who loves what they get to do for a living.</p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5', paddingBottom: '16px'}}>
                                                <p>You'll learn more about:
                                                </p>
                                                <ul>
                                                  <li>A Black IPA inspired by a Yokai monster of Japanese folklore</li>
                                                  <li>Kölsch that doesn't sacrifice quality for dollars</li>
                                                  <li>How this rockstar-poet-artist-brewer supports his community</li>
                                                </ul>
                                                <p style={{paddingTop: '16px'}}>Let's get to it 🚀</p>
                                              </div>
                                              <EmailFormInPage />
                                              </td></tr></tbody></table><table cellSpacing={0} cellPadding={0} border={0} align="center" width="100%"><tbody><tr><td className="container" style={{paddingTop: '15px', paddingBottom: '15px'}}><div className="text-block" style={{lineHeight: '150%'}}><span className="fr-unprocessed" style={{color: 'rgb(40, 50, 78)'}}><span style={{color: 'rgb(40, 50, 78)'}}> </span></span><div style={{textAlign: 'center'}}><span className="fr-unprocessed" style={{color: 'rgb(40, 50, 78)'}}><span style={{color: 'rgb(40, 50, 78)'}}>***</span><br /></span></div></div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>So, you spent 9 years homebrewing before starting up your professional brewing operation 2 years ago. What lead you to go pro? What was that transition like?</strong>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>I was working as the head brewer for a brewery in Pilot Point, TX that ended up shutting its doors and going out of business in 2018. I think that was the final spark of motivation that made me finally decide to take my home brewery and make it pro.</p>
                                                <p style={{paddingBottom: '16px'}}>My wife (Ms. Toast) started the process of researching everything we would need to make it happen and we took it one step at a time starting by getting our LLC and then talking to the TABC (Texas Alcoholic Beverage Commission). We had already made plans to rent space from Denton County Brewing Company and brew our beer there as an alternating proprietorship, but the TABC told us that we couldn’t do that due to DCBC having a brew pub license and not a brewery license.</p>
                                                <p style={{paddingBottom: '16px'}}>Luckily we found a brewery in Grapevine (Hop and Sting) that was willing to take us in and after all the licensing, city, state, county, and TTB, we were able to start legally producing our beer for sale to the public.</p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>What's your favorite beer that you've produced? Why is it special to you?</strong>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>My favorite beer that we produce is probably <a href="https://untappd.com/b/toasty-bros-bakeneko/3267688" style={{color: '#6821AB', textDecoration: 'underline'}} target="_blank" rel="noreferrer">Bäkéneko Black IPA</a>. The Cascadian Dark Ale is my favorite beer style and its not an easy one to find in the craft beer world. There aren’t many breweries that make one for distribution.</p>
                                                <p style={{paddingBottom: '16px'}}>The name comes from Japanese folklore meaning “ghost cat” or “monster cat”. I like the stories about the many Yokai Monsters and the Bäkéneko has always been one of my favorite Yokai. I am also an artist and graphic designer of sorts and made some pretty cool art works of the Bakeneko</p>
                                              </div></td></tr></tbody></table><table border={0} className="container" align="center" cellPadding={0} cellSpacing={0} width="100%" style={{marginBottom: '5px', marginTop: '5px'}}>
                                        <tbody>
                                          <tr>
                                            <td align="center" className="hero" style={{borderCollapse: 'collapse', borderSpacing: 0, margin: 0, padding: 0}} valign="top">
                                              <img src="/assets/images/toast/toast_black_ipa.jpg" width={620} style={{width: '100%', maxWidth: '100%', objectFit: 'cover', padding: 0, outline: 'none', border: 'none', display: 'block'}} />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table><table width="100%" style={{tableLayout: 'fixed', width: '100%', marginBottom: '16px'}}><tbody><tr><td style={{padding: '8px 0px', borderCollapse: 'collapse', borderSpacing: '0px', margin: '0px'}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5', width: '100%', paddingBottom: '32px', textAlign: 'center', wordWrap: 'break-word'}}>
                                                Toast's Bäkéneko Black IPA
                                              </div>
                                            </td></tr></tbody></table><table border={0} className="container" align="center" cellPadding={0} cellSpacing={0} width="100%" style={{marginBottom: '5px', marginTop: '5px'}}>
                                        <tbody>
                                          <tr>
                                            <td align="center" className="hero" style={{borderCollapse: 'collapse', borderSpacing: 0, margin: 0, padding: 0}} valign="top">
                                              <img src="/assets/images/toast/toast_bakeneko_art.jpg" width={620} style={{width: '100%', maxWidth: '100%', objectFit: 'cover', padding: 0, outline: 'none', border: 'none', display: 'block'}} />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table><table width="100%" style={{tableLayout: 'fixed', width: '100%', marginBottom: '16px'}}><tbody><tr><td style={{padding: '8px 0px', borderCollapse: 'collapse', borderSpacing: '0px', margin: '0px'}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5', width: '100%', paddingBottom: '32px', textAlign: 'center', wordWrap: 'break-word'}}>
                                                Bäkéneko - Acrylic painting by the one and only Toast
                                              </div>
                                              <div style={{textAlign: 'center', color: '#28324E', fontSize: '14px', lineHeight: '1.5', opacity: '0.5', wordWrap: 'break-word'}}>
                                                https://www.instagram.com/p/B-gMcSBH5cq/</div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>As a brewer, is there a beer style that's been especially challenging for you? Do you have any tips for others trying to brew it?</strong>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>Our lighter beers sell the best and one of our most popular beers is also one of the more challenging to brew. It’s called <a href="https://untappd.com/b/toasty-bros-long-tacky-old-slacks-original/3204054" style={{color: '#6821AB', textDecoration: 'underline'}} target="_blank" rel="noreferrer">Long Tacky Old Slacks Original</a>. It’s a German style Kölsch.</p>
                                                <p style={{paddingBottom: '16px'}}>The yeast we use in this can put off very different flavors depending on the temperatures it is fermented at. The fermentation process of this beer takes 3-4 weeks. It starts on the cold end for 5 days, then we raise 5 degrees for 2 days then we drop in back down 5 degrees for a week then we have to drop the temp 5 degrees each day until it gets down to 33F and let it rest there (this is called cold conditioning or lagering) until it tastes the way it should.</p>
                                                <p style={{paddingBottom: '16px'}}>It takes time but the end result is worth it!</p>
                                                <p style={{paddingBottom: '16px'}}>My tip for the Kölsch style is fresh ingredients and patience during fermentation. Keep it as cold as you can and give it plenty of time. Many breweries try to rush beer to turn over faster and increase production but the quality of beer is sacrificed. We take our time with each beer to ensure its at its best.</p>
                                              </div></td></tr></tbody></table><table border={0} className="container" align="center" cellPadding={0} cellSpacing={0} width="100%" style={{marginBottom: '5px', marginTop: '5px'}}>
                                        <tbody>
                                          <tr>
                                            <td align="center" className="hero" style={{borderCollapse: 'collapse', borderSpacing: 0, margin: 0, padding: 0}} valign="top">
                                              <img src="/assets/images/toast/toast_long_tacky_old_slacks_original.jpg" width={620} style={{width: '100%', maxWidth: '100%', objectFit: 'cover', padding: 0, outline: 'none', border: 'none', display: 'block'}} />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table><table width="100%" style={{tableLayout: 'fixed', width: '100%', marginBottom: '16px'}}><tbody><tr><td style={{padding: '8px 0px', borderCollapse: 'collapse', borderSpacing: '0px', margin: '0px'}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5', width: '100%', paddingBottom: '32px', textAlign: 'center', wordWrap: 'break-word'}}>
                                                Long Tacky Old Slacks Original - German style Kölsch
                                              </div>
                                            </td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>Toasty Bros seems like an authentic locally-driven, small scale operation. Is this intentional? What are some advantages to being smaller?</strong>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>Absolutely intentional. We are and always will be a Mom &amp; Pop operation. Denton has many local small businesses and it’s one of things I love so much about living here. They are unique and full of spirit. I avoid the cookie cutter chain businesses that suck the soul out of cities and choose to support local shops whenever I can. There seems to be a lot of support for the Mom &amp; Pops here in Denton and I hope it stays that way.</p>
                                                <p style={{paddingBottom: '16px'}}>The advantages... Well I get to brew whatever I want whenever I want and sell the beer to whoever I want (so long as they have the proper licenses of course). I deliver each keg of beer myself and get to build some good relationships with the bars, restaurants, and craft beer stores around town.</p>
                                                <p style={{paddingBottom: '16px'}}>You won’t find Toasty Bros. at Walmart or Total Wine either.</p>
                                                <p style={{paddingBottom: '16px'}}>The local owned businesses we support in turn support us and together we thrive. It’s kinda cool, I think, that our beer is limited and exclusive to so few places and while we do want to continue to grow it’s going to be a gradual evolution and definitely has a stopping point.</p>
                                                <p style={{paddingBottom: '16px'}}>Quality beer, quality relationships, and quality of life will not be sacrificed for dollar signs.</p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>Lastly, simply, what motivates you to brew, beyond beer?</strong>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>“Do what you love”. Goes back to that quality of life idea. If I could do anything for a living I would spend each day making beer and creating art.</p>
                                                <p style={{paddingBottom: '16px'}}>Why? I guess it’s because I have this itching desire to express myself. Also it makes me feel like a rockstar when I’m rolling a keg down the street and I hear someone shout out of their apartment window “Toast!”. I do enjoy feeling like a rockstar. I’ve done some open mic spoken word poetry stuff and I think for that same reason... being up on stage expressing myself through my art... it’s a cool feeling.</p>
                                                <p style={{paddingBottom: '16px'}}>I also love to throw darts and play video games but I’m not planning to make a career out of those two things. Really what motivates me is the people who choose to follow this endeavor, collaborate, and drink the beer. They make me feel special... loved even. </p>
                                                <p style={{paddingBottom: '16px'}}>And don’t we all just want to feel loved? I’m a big dreamer. I’ve been that way since I was a kid. I have this big dream to own a small business in Denton that let’s me express myself through beer and art. My wife loves to bake so we are going to incorporate a small bakery into the space. A brewery / bakery / art studio. Seems like a good fit for Denton.</p>
                                                <p style={{paddingBottom: '16px'}}>Ms. Toast and I. We are making it happen. Living our best life.</p>
                                              </div></td></tr></tbody></table><table border={0} className="container" align="center" cellPadding={0} cellSpacing={0} width="100%" style={{marginBottom: '5px', marginTop: '5px'}}>
                                        <tbody>
                                          <tr>
                                            <td align="center" className="hero" style={{borderCollapse: 'collapse', borderSpacing: 0, margin: 0, padding: 0}} valign="top">
                                              <img src="/assets/images/toast/toast.jpg" width={620} style={{width: '100%', maxWidth: '100%', objectFit: 'cover', padding: 0, outline: 'none', border: 'none', display: 'block'}} />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table><table width="100%" style={{tableLayout: 'fixed', width: '100%', marginBottom: '16px'}}><tbody><tr><td style={{padding: '8px 0px', borderCollapse: 'collapse', borderSpacing: '0px', margin: '0px'}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5', width: '100%', paddingBottom: '32px', textAlign: 'center', wordWrap: 'break-word'}}>
                                                Toast of Denton, TX
                                              </div>
                                              <EmailFormInPage />
                                            </td></tr></tbody></table><table cellSpacing={0} cellPadding={0} border={0} align="center" width="100%"><tbody><tr><td className="container" style={{paddingTop: '15px', paddingBottom: '15px'}}><div className="text-block" style={{lineHeight: '150%'}}><span className="fr-unprocessed" style={{color: 'rgb(40, 50, 78)'}}><span style={{color: 'rgb(40, 50, 78)'}}> </span></span><div style={{textAlign: 'center'}}><span className="fr-unprocessed" style={{color: 'rgb(40, 50, 78)'}}><span style={{color: 'rgb(40, 50, 78)'}}>***</span><br /></span></div></div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '8px'}}>What strikes me about Toast's story is that he <strong>feels loved by spreading love, while doing what he loves, with those he loves.</strong></p>
                                                <p style={{paddingBottom: '8px'}}>He loves Denton and Denton loves him back.</p>
                                                <p style={{paddingBottom: '8px'}}>He keeps it local. Keeps it real. I think we'd all love to have a guy like Toast in our city, making it live.</p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#4cb14c', fontSize: '16px', lineHeight: '1.5', backgroundColor: '#F3F9F0', padding: '8px', borderRadius: '4px', margin: '15px 0'}}>
                                                Thank you <strong>Toast</strong> for giving us a glimpse into what you do and why you do it. The world needs dreamers, so dream on.
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%', marginBottom: '16px'}}><tbody><tr><td style={{padding: '0px 0px 8px', borderCollapse: 'collapse', borderSpacing: '0px', margin: '0px'}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>Also, if you've read this far - thank you. You are the reason Crafted exists, the reason why I love collecting stories of brewers and sharing them this this community.</p>
                                                <p style={{paddingBottom: '16px'}}>If you liked this story, share it with someone you think would also appreciate it. Share it with your mom, your neighbor, your Facebook friend from high school - It really helps a lot!</p>
                                                <p style={{paddingBottom: '16px'}}>Take care, until next time 👋</p>
                                                <p style={{paddingBottom: '16px'}}>Cheers! 🍻</p>
                                                <p style={{paddingBottom: '16px'}}>- Jake</p></div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}}>
                                        <tbody>
                                          <tr>
                                            <td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top">
                                              <div style={{color: '#4cb14c', fontSize: '16px', lineHeight: '1.5', backgroundColor: '#F3F9F0', padding: '8px', borderRadius: '4px'}}>
                                                <div style={{padding: '8px'}}>
                                                  This week's story was made possible by <strong>Yakima Valley Hops</strong>. <a href="https://www.yakimavalleyhops.com/products/barth-haas-hop-aroma-standards-kit" style={{textDecoration: 'underline', color: '#6821AB'}} target="_blank" rel="noreferrer">Check out their new hop aromas kit, which gives you new language for your hoppy creations.</a>
                                                </div>
                                              </div></td>
                                          </tr>
                                        </tbody>
                                      </table><table border={0} className="container" align="center" cellPadding={0} cellSpacing={0} width="100%" style={{marginBottom: '5px', marginTop: '-8px'}}>
                                        <tbody>
                                          <tr>
                                            <td align="center" className="hero" style={{borderCollapse: 'collapse', borderSpacing: 0, margin: 0, padding: 0}} valign="top">
                                              <a href="https://www.yakimavalleyhops.com/products/barth-haas-hop-aroma-standards-kit"><img src="/assets/images/yakima_valley_hops_kit_1000x500.jpeg" width={620} style={{width: '100%', maxWidth: '100%', objectFit: 'cover', padding: 0, outline: 'none', border: 'none', display: 'block'}} alt="Yakima Valley Hops Kit" /></a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
          <hr />
          <div className="spacer-div"></div>
        </div>
        <div className="blog-right"></div>
      </main>
    </div>
    <div id="overlay">
      <div id="loader-object"></div>
    </div>
    <Footer />
  </LayoutBlog>
);

export default BlogMain;
